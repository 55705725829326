import { Box, Grid, Typography } from "@material-ui/core";

import Appbar from "components/Appbar";
import { Movie } from "types/movie";
import MovieCard from "components/MovieCard";
import MovieSearch from "components/MovieSearch";
import SavedMovieCard from "components/SavedMovieCard";
import { useState } from "react";
import TopImdbMovies from "components/TopImdbMovies";

type MoviesOrNull = Movie[] | null;

const getWatchList = (): MoviesOrNull => {
  const movies: any = localStorage.getItem("watchList");
  return JSON.parse(movies);
};

export const checkIfInList = (title: string): boolean => {
  let prevList = getWatchList();
  // false of list is empty
  if (!prevList || prevList.length === 0) return false;

  let found: boolean = false;

  prevList.forEach((mov) => {
    //title matches
    if (mov.Title === title) {
      found = true;
      return;
    }
  });

  //no match
  return found;
};

function App() {
  const [movie, setMovie] = useState<null | Movie>(null);
  const [savedMovies, setSavedMovies] = useState<Movie[]>(getWatchList() ?? []);

  const removeFromWatchList = (title: string) => {
    let currList = getWatchList();
    if (currList !== null) {
      let newList: Movie[] = currList?.filter((movie) => movie.Title !== title);
      setSavedMovies(newList);
      localStorage.setItem("watchList", JSON.stringify(newList));
    }
  };

  const addToWatchList = (movie: Movie): void => {
    setSavedMovies([...savedMovies, movie]);
    let prevList = getWatchList();
    let newList: Movie[] = [];
    if (prevList && prevList.length) {
      newList = [...prevList];
    }
    //save new movie
    newList.push(movie);
    //save to local storage
    localStorage.setItem("watchList", JSON.stringify(newList));
  };

  const removeSearchedMovie: React.MouseEventHandler<HTMLImageElement> = () => {
    setMovie(null);
  };

  return (
    <div className="App">
      <Appbar remove={removeSearchedMovie} />
      <MovieSearch setMovie={setMovie} />
      {movie && (
        <MovieCard
          movie={movie}
          addToWatchList={addToWatchList}
          addToList={addToWatchList}
        />
      )}
      {!savedMovies.length && (
        <Typography
          variant="h6"
          style={{
            margin: "20px 0px 10px",
            textAlign: "center",
            color: "#9a9393",
            padding: "5px 15px",
          }}
        >
          No movie in the watch list.To add in the list, try searching and
          clicking on the ❤ icon.
        </Typography>
      )}
      {savedMovies && savedMovies.length > 0 && (
        <Box p={4}>
          <Typography
            variant="h6"
            style={{ margin: "20px 0px 10px", fontWeight: "bold" }}
          >
            Watch List
          </Typography>
          <Grid container spacing={3} justify="center">
            {savedMovies &&
              savedMovies.map((mov) => (
                <Grid item key={mov.Title}>
                  <SavedMovieCard
                    movie={mov}
                    removeFromList={removeFromWatchList}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      )}

      <TopImdbMovies />
    </div>
  );
}

export default App;
