import { Box, makeStyles, Typography } from "@material-ui/core";
import { TopMovie } from "types/movie";
import { Scrollbars } from "react-custom-scrollbars-2";

interface props {
  movie: TopMovie;
}

const ImdbMovieCard: React.FC<props> = ({ movie }) => {
  const classes = useStyles();

  const { banner, genre, imdb, plot, title } = movie;

  return (
    <Scrollbars
      style={{ width: 220, height: 300, padding: "0px 5px" }}
      autoHide
      autoHideTimeout={1000}
    >
      <Box className={classes.wrapper}>
        <img src={banner} alt="poster" className={classes.poster} />
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h5" align="left" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="body2">
                <span>IMDB :</span> {imdb}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.info}>
            <Typography variant="body1">
              <span>Genre:</span> {genre}
            </Typography>
            <Typography variant="h6" className={classes.plot}>
              {plot}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Scrollbars>
  );
};

export default ImdbMovieCard;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 220,
    maxHeight: 500,
    "& span": {
      fontWeight: 600,
      color: "orange",
    },
  },
  poster: {
    height: 250,
    width: "auto",
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingRight: 10,
    marginBottom: 5,
  },
  plot: {
    fontSize: 16,
    fontWeight: 600,
  },
  info: {
    "& p": {
      fontSize: 14,
      margin: "5px 0px",
      color: "#483e3e",
    },
  },
  love: {
    width: 30,
    height: "auto",
    color: "#f44336",
    cursor: "pointer",
  },
}));
