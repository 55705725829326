import { AppBar, Toolbar, makeStyles } from "@material-ui/core";

import Logo from "../asset/logo.png";

const Appbar: React.FC<{
  remove: React.MouseEventHandler<HTMLImageElement>;
}> = ({ remove }) => {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.appbar} color="transparent">
      <Toolbar>
        <img src={Logo} alt="logo" className={classes.logo} onClick={remove} />
      </Toolbar>
    </AppBar>
  );
};
export default Appbar;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    boxShadow: "none",
    // background: "#fff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "#000",
  },
  logo: {
    height: 40,
    cursor: "pointer",
  },
}));
