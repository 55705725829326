import { Box, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { Movie } from "types/movie";

interface props {
  movie: Movie;
  removeFromList: Function;
}

const SavedMovieCard: React.FC<props> = ({ movie, removeFromList }) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box className={classes.wrapper}>
      <img src={movie.Poster} alt="poster" className={classes.poster} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h5" align="left" className={classes.title}>
            {movie.Title}
          </Typography>
          <Typography variant="body2">
            <span>IMDB :</span> {movie.imdbRating}
          </Typography>
        </Box>
        <Box alignSelf="baseline">
          {open ? (
            <svg
              className={classes.arrowIcon}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setOpen(false)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          ) : (
            <svg
              className={classes.arrowIcon}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setOpen(true)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 14l-7 7m0 0l-7-7m7 7V3"
              ></path>
            </svg>
          )}
        </Box>
      </Box>

      {open && (
        <Box className={classes.info}>
          <Typography variant="body1">
            <span>Genre:</span> {movie.Genre}
          </Typography>
          <Typography variant="h6" className={classes.plot}>
            {movie.Plot}
          </Typography>
          <Typography variant="body1">
            <span>Duration:</span> {movie.Runtime}
          </Typography>
          <Typography variant="body1">
            <span>Language:</span> {movie.Language}
          </Typography>
          <Typography variant="body1">
            <span>Director:</span> {movie.Director}
          </Typography>
          <Typography variant="body1">
            <span>Actors:</span> {movie.Actors}
          </Typography>
          <Box my={1}>
            <svg
              className={classes.filledlove}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => removeFromList(movie.Title)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              ></path>
            </svg>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SavedMovieCard;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 180,
    "& span": {
      fontWeight: 600,
      color: "orange",
    },
  },
  poster: {
    height: 250,
    width: "auto",
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingRight: 10,
    marginBottom: 5,
  },
  arrowIcon: {
    width: 20,
    color: "#ff9800",
    userSelect: "none",
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  plot: {
    fontSize: 16,
    fontWeight: 600,
  },
  info: {
    animation: `$anim 300ms ease`,
    "& p": {
      fontSize: 14,
      margin: "5px 0px",
      color: "#483e3e",
    },
  },
  love: {
    width: 30,
    height: "auto",
    color: "#f44336",
    cursor: "pointer",
  },
  filledlove: {
    width: 30,
    height: "auto",
    color: "#f44336",
    fill: "#f44336",
    cursor: "pointer",
  },
  "@keyframes anim": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}));
