import { Box } from "@material-ui/core";
import { Movie } from "types/movie";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { checkIfInList } from "App";
import { makeStyles } from "@material-ui/core/styles";

interface Iprops {
  movie: Movie;
  addToWatchList: Function;
  addToList: Function;
}

const MovieCard: React.FC<Iprops> = ({ movie, addToWatchList, addToList }) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <img src={movie.Poster} alt="poster" className={classes.poster} />
      <Box className={classes.info}>
        <Box mb={2}>
          <Typography variant="h5">{movie.Title}</Typography>
          <Typography variant="body1">
            <span>Year:</span> {movie.Year}
          </Typography>
          <Typography variant="body1">
            <span>Genre:</span> {movie.Genre}
          </Typography>
          <Typography variant="body1">
            <span>Duration:</span> {movie.Runtime}
          </Typography>
          <Typography variant="body1">
            <span>Language:</span> {movie.Language}
          </Typography>
        </Box>
        <Typography variant="body1" className={classes.plot}>
          {movie.Plot}
        </Typography>
        <Typography variant="body1">
          <span>Director:</span> {movie.Director}
        </Typography>
        <Typography variant="body1">
          <span>Actors:</span> {movie.Actors}
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">
            <span>IMDB:</span> {movie.imdbRating}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body1">
            <span>Awards: </span>
            {movie.Awards}
          </Typography>
        </Box>
        {checkIfInList(movie.Title) ? (
          <Box mt={2}>
            <svg
              className={classes.filledlove}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              // onClick={() => addToList(movie)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              ></path>
            </svg>
          </Box>
        ) : (
          <Box mt={2}>
            <svg
              className={classes.love}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => addToList(movie)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              ></path>
            </svg>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MovieCard;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    width: "min(800px, 80%)",
    margin: "auto",
    "@media (max-width:650px)": {
      flexDirection: "column",
    },
  },
  poster: {
    marginRight: 20,
    width: "auto",
    "@media (max-width:650px)": {
      marginRight: 0,
      height: "auto",
      width: "100%",
      overFlow: "hidden",
      objectFit: "cover",
    },
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 20,
    "& span": {
      fontWeight: "bold",
      color: "orange",
    },
  },
  love: {
    width: 30,
    height: "auto",
    color: "#f44336",
    cursor: "pointer",
  },
  filledlove: {
    width: 30,
    height: "auto",
    color: "#f44336",
    fill: "#f44336",
    cursor: "pointer",
  },
  plot: {
    fontSize: 18,
    marginBottom: 16,
  },
}));
