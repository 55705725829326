import * as React from "react";

import { Button, CircularProgress, TextField } from "@material-ui/core";

import ErrorAlert from "./ErrorAlert";
import { Movie } from "types/movie";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px 0px",
    alignItems: "center",
  },
  searchBar: {
    width: "clamp(250px, 60%, 500px)",
  },
  btn: {
    marginTop: 15,
  },
}));

export interface Props {
  setMovie: Function;
}

interface Error {
  Response: "string";
  Error: "string";
}

type MovieOrError = Movie | Error;

function isError(resp: Movie | Error): resp is Error {
  return (resp as Error).Error !== undefined;
}

const MovieSearch: React.FC<Props> = ({ setMovie }) => {
  const [query, setQuery] = React.useState("");
  const [notFound, setNotFound] = React.useState(false);

  const { isFetching, isLoading, error, refetch } = useQuery<MovieOrError>(
    "search-movie",
    async () => {
      const resp = await fetch(
        `https://www.omdbapi.com/?t=${query}&i=tt3896198&apikey=48ce2f2e`
      );
      return await resp.json();
    },
    {
      enabled: false,
      onSuccess: (resp) => {
        if (isError(resp)) {
          setNotFound(true);
          return;
        } else {
          setMovie(resp as Movie);
          setNotFound(false);
        }
        return;
      },
    }
  );

  const searchMovie = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    refetch();
  };

  const classes = useStyles();

  return (
    <form onSubmit={searchMovie}>
      {error && <ErrorAlert />}
      {notFound && <ErrorAlert text="Movie not Found" />}
      <div className={classes.wrapper}>
        <TextField
          type="text"
          label="Movie name"
          variant="outlined"
          required
          onChange={(e) => setQuery(e.target.value)}
          className={classes.searchBar}
        />
        {isFetching ? (
          <CircularProgress size={25} style={{ marginTop: 20 }} />
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<SearchIcon />}
            className={classes.btn}
            disabled={isFetching || isLoading}
          >
            Search
          </Button>
        )}
      </div>
    </form>
  );
};

export default MovieSearch;
