import { makeStyles } from "@material-ui/core";

const ErrorAlert: React.FC<{ text?: string }> = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <p>{text ?? "Something went wrong"}</p>
    </div>
  );
};

export default ErrorAlert;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    padding: 5,
    textAlign: "center",
    background: "#ff42426e",
    color: "#ff2424",
  },
}));
