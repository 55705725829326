import { Box, Grid, Typography } from "@material-ui/core";
import data from "../data/imdbMovies.json";
import ImdbMovieCard from "./ImdbMovieCard";

const TopImdbMovies: React.FC = () => {
  return (
    <Box p={4}>
      <Typography
        variant="h6"
        style={{ margin: "20px 0px 10px", fontWeight: "bold" }}
      >
        Top Imdb Movies
      </Typography>
      <Grid container spacing={4} justify="center">
        {data.map((movie) => (
          <Grid item key={movie.title}>
            <ImdbMovieCard movie={movie} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TopImdbMovies;
